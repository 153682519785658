import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CoursesService } from "src/app/Services/Courses.service";
import { DomSanitizer } from "@angular/platform-browser";
declare var $;
import { AuthService } from "src/app/Services/Auth/auth.service";

import { InitProvider } from "../../init-provider";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { IaMessagesService } from "src/app/Services/ia-messages.service";
interface Message {
  text: string;
  message?: string;
  from?: any;
  sent: boolean;
  timestamp: Date;
  date_time?: any;
}
@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("300ms ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("300ms ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class FaqsComponent implements OnInit {
  messages: any = [];
  newMessage: string = "";
  cards = [
    { title: "¿Como realizar un render utilizando herramientas de tendencia?" },
    { title: "¿Como realizar un render utilizando herramientas de tendencia?" },
    { title: "¿Como realizar un render utilizando herramientas de tendencia?" },
  ];
  submitted = false;
  sendingStatus = {
    loading: false,
    message: null,
    error: false,
    submitted: false,
  };
  public showChatcontainer = true;
  public showChat = true;
  public isThinking = false;
  videoId = "383643119";
  videoLoaded = false;
  isVideoShown = false;
  videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
    "https://player.vimeo.com/video/" + this.videoId
  );
  faqs = [];
  image_top = "";
  image_bottom = "";

  currentUser = this.authService.user;

  constructor(
    private coursesService: CoursesService,
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    public initProvider: InitProvider,
    private messageService: IaMessagesService
  ) {
    const company = this.initProvider.getSettings();
    this.image_top = company.help_top_image.large;
    this.image_bottom = company.help_bottom_image.large;
    //console.log('company');
    //console.log(this.image_top);
    //console.log(this.image_bottom);

    const json = JSON.parse(company.faq_json);
    if (json.faqs) {
      this.faqs = json.faqs.map((faq) => {
        let actual = faq;
        actual.collapsed = false;
        return actual;
      });
    }
  }

  ngOnInit() {
    this.gethistory();
    this.authService
      .getProfileData()
      .then((response) => {
        this.showChat = response?.ai_chat_enabled;
      })
      .catch((error) => {});
  }

  setAllClosed(title) {
    this.faqs = this.faqs.map((faq) => {
      return { ...faq, collapsed: faq.title == title };
    });
  }

  onSubmit(contactForm: NgForm) {
    this.sendingStatus.submitted = true;
    this.sendingStatus.message = null;
    console.log("contact formm", this.currentUser, contactForm.value);
    if (contactForm.invalid) {
      return;
    }
    this.sendingStatus.loading = true;

    contactForm.value.name =
      this.currentUser.first_name + " " + this.currentUser.last_name;
    contactForm.value.email = this.currentUser.email;

    this.coursesService
      .sendContactForm(contactForm.value)
      .then(() => {
        this.sendingStatus.loading = false;
        this.sendingStatus.message = "Message was sent";
        this.sendingStatus.error = false;
        this.sendingStatus.submitted = false;

        contactForm.resetForm();
      })
      .catch((error) => {
        this.sendingStatus.loading = false;
        this.sendingStatus.error = true;
        this.sendingStatus.message =
          "Error sending message. Please try again later";
        this.sendingStatus.submitted = false;
      });
  }

  showError(form: NgForm, fieldName) {
    if (!this.sendingStatus.submitted) {
      return false;
    }
    return form.control.get(fieldName) && !form.control.get(fieldName).valid;
  }

  openVideoModal(videoId) {
    this.videoId = videoId;
    setTimeout(() => {
      this.isVideoShown = false;
      this.videoLoaded = true;
      this.initializeVideoPlayer(videoId);
    }, 200);
  }

  initializeVideoPlayer(videoId) {
    this.videoId = videoId;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://player.vimeo.com/video/" + this.videoId
    );
    $("#modal-faq").on("hide.bs.modal", () => {
      this.videoId = null;
      this.videoUrl = null;
      try {
        document.getElementById("videoFrame").remove();
      } catch (error) {}
    });
  }

  showVideo() {
    return this.videoId && this.videoUrl;
  }

  public startChat(text: string) {
    this.newMessage = text;
    this.sendMessageToFaqs();
  }

  public gethistory() {
    this.isThinking = true;
    this.messageService
      .getMessageOfFaqs(this.currentUser)
      .then((resp) => {
        this.isThinking = false;
        this.messages = resp?.data;
        // Invertir el array messages
        if (this.messages && this.messages.length > 0) {
          this.messages = [...this.messages].reverse(); // Copia y revierte el array
        }
        if (this.messages.length == 0) {
          this.showChatcontainer = false;
        }
        setTimeout(() => {
          this.scrollToBottom();
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        this.isThinking = false;
      });
  }
  public sendMessageToFaqs() {
    this.showChatcontainer = true;
    if (this.newMessage.trim() == "") {
      return;
    }
    const messageToSend = this.newMessage.trim();
    this.messages.push({
      message: messageToSend,
      role: "user",
    });

    this.newMessage = "";
    this.isThinking = true;

    if (messageToSend !== "") {
      this.isThinking = true;
      this.messageService
        .sendMessageToFaqs(this.currentUser, messageToSend)
        .then((response) => {
          this.isThinking = false;
          this.messages.push({
            message: response.text,
            role: "assistant",
          });
          setTimeout(() => {
            this.scrollToBottom();
          }, 500);
          this.newMessage = "";
          this.autoResizeTextarea();
        })
        .catch((error) => {
          this.isThinking = false;
        });
    }
  }
  @ViewChild("scrollMe") private scrollContainer!: ElementRef;
  private scrollToBottom() {
    const container = this.scrollContainer.nativeElement;
    const lastMessage = container.querySelector("#lastMessage");
    if (lastMessage) {
      lastMessage.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
  autoResize(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = "auto"; // Restablecer la altura

    // Ajustar la altura a la del contenido
    textarea.style.height = textarea.scrollHeight + "px";

    // Si el contenido está vacío, restablecer la altura a la altura mínima
    if (textarea.value === "") {
      textarea.style.height = "auto"; // O puedes establecer una altura mínima específica
    }
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Evitar el salto de línea
      this.sendMessageToFaqs();
    }
  }

  private autoResizeTextarea() {
    const textarea = document.querySelector(
      'textarea[name="newMessage"]'
    ) as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = "auto"; // Restablecer la altura
      textarea.style.height = `${textarea.scrollHeight}px`; // Ajustar a la altura del contenido
    }
  }
}
