import ImageModel from '../Interfaces/ImageModel';
import {BlockModel} from '../Interfaces/BlockModel';

export class CompanyModel {
  id: string;
  name: string;
  api_url: string;
  base_url: string;
  logo: any;
  shadow_logo: any;
  home_image: any;
  sign_up_image: any;
  sign_in_image: any;
  favicon: string;
  home_text_title: string;
  home_text_description: string;
  login_text_title: string;
  login_text_description: string;
  register_text_title: string;
  register_text_description: string;
  color_1: string;
  color_2: string;
  color_3: string;
  color_4: string;
  color_5: string;
  faq_json: string;
  mandatory_fields: string;
  term_and_conditions: string;
  allow_registration: boolean;
  currency: any;
  language: string;
  legal_notice: string;
  cookies_warning: string;
  timezone: string;
  google_analytics_id: string;
  google_ads_id: string;
  font_title: string;
  font_text: string;
  view_plans_logout: boolean;
  domain: string;
  iva_google_analytics: any;
  google_ads_convertion: any;
  facebook_pixel_id: string;  
  hotjar_id: string;
  title_for_teachers_es = 'Listado de profesores';
  title_for_teachers_en = 'List of Teachers';
  teachers_menu_es = 'Profesores';
  teachers_menu_en = 'Teachers';
  whatsapp_show_icon: boolean;
  facebook_show_icon: boolean;
  instagram_show_icon: boolean;
  facebook: any;
  instagram: any;
  state: {
    id: number;
    name: string;
    key: string;
  };
  bank_transfer_text: string;
  no_more_image: any;
  help_bottom_image: any;
  help_top_image: any;
  suscriber_text: string;
  suscriber_image: any;
  redirect_to_plans: boolean;
  registration_type: number;
  userProfile: any;
  request_billing_info: any;
  has_programs: boolean;
  has_presential_events:boolean;
  menu_option_en: string;
  menu_option_es: string;
  menu_option_url: string;
  request_additional_data: boolean;
  request_user_profile: boolean;
  custom_css: string;
  footer?: {
    web?: string;
    twitch?: string;
    facebook?: string;
    twitter?: string;
    instagram?: string;
    youtube?: string;
    linkedin?: string;
    pinterest?: string;
    whatsapp?: string;
    telegram?: string;
    snapchat?: string;
    tiktok?: string;
    extra_logos: Array<{
      url: string;
      image: ImageModel;
      text: string;
    }>;
    url: any;
    email: any;
    zip: any;
    state: any;
    address: string;
    city: string;
    phone: string;
    background_color: string;
    menu_color: string;
    text: string;
  };
  whatsapp_number?: string;
  blocks: Array<BlockModel>;
  show_free_courses: boolean;
  require_user_interests:boolean;
  blog_comment_allow: boolean;
  subcription_newslatter_allow:boolean;
  header_script:string;
}
