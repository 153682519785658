import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { InitProvider } from "src/app/init-provider";
import { AuthService } from "src/app/Services/Auth/auth.service";
import { environment } from "src/environments/environment";
import {ApiService} from '../../Services/Api.service';
import {CoursesService} from 'src/app/Services/Courses.service';


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @Output() changeView = new EventEmitter<any>();
  @Input() isPurchasing: boolean;
  @Input() courseId: string;
  @Input() price: number;
  @Input() eventId: string;
  @Input() productId: string;
  @Input() programId: string;
  @Input() is_just_for_suscription: any=false;
  public form: FormGroup;
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  showLoginError = false;
  loading = false;
  passwordRecoveryUrl = environment.baseUrl + "/password/reset";
  company: any;
  view_plans_logout = false;

  constructor(
    private apiService: ApiService,
    private coursesService: CoursesService,

    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private initProvider: InitProvider,
    private activatedRoute: ActivatedRoute
  ) {
    this.company = initProvider.getSettings();
    this.passwordRecoveryUrl = this.company.base_url + "/password/reset";
  }

  ngOnInit() {
    console.log('is_just_for_suscription',this.is_just_for_suscription)
    this.view_plans_logout = this.company.view_plans_logout;

    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ["", Validators.required],
    });
  }

  // login method is used when form field is valid then router will be navigate to the home page.
  async logIn() {
    let self=this;
    if (this.form.valid) {
      this.loading = true;
      try {
        await this.authService.logIn(
          this.form.get("email").value,
          this.form.get("password").value
        );
        if (this.isPurchasing) {
          if (this.courseId) {
            if (this.price==0){//si el curso es 0 entonces es free lo mando al curso directo
              
              //obtengo datos del curso
              this.coursesService
              .getCourseById(this.courseId)
              .then((courseData) => {
                //marco el curso como comprado
                this.apiService
                .post("courses/free-purchase", {
                  course_id: courseData.data.id,
                  free_token: courseData.data.free_token,
                })
                .then((data) => {
                  window.location.href="/courses/profile/"+self.courseId
                })
              })

            }else{
               
              if (this.is_just_for_suscription){
                window.location.href="/logged/profile?section=plans"
                
              }else{
                window.location.href="/purchases/course/"+this.courseId
              }

            }
            //this.router.navigate(["/purchases", "course", this.courseId]);
          }
          if (this.eventId) {
            window.location.href="/purchases/event/"+this.eventId
          }
          if (this.productId) {
            window.location.href="/purchases/product/"+this.productId
          }
          if (this.programId) {
            if (this.is_just_for_suscription){
              window.location.href="/logged/profile?section=plans"
              
            }else{
              window.location.href="/purchases/program/"+this.programId
            }
          }
        } else {
          this.activatedRoute.queryParams.subscribe((params) => {
            if (params["redirect"]) {
              return (window.location.href = params["redirect"]);
            }
            return (window.location.href = "/home");
          });
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.showLoginError = true;
      }
    } else {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
    }
  }
  public goToRegister() {
    this.changeView.emit("register");
  }
}
